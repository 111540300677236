const IPIFY_URL = "https://api.ipify.org/?format=json"

export async function getUserOuterIpAddress() {
    try {
        const response = await fetch(IPIFY_URL);
        const data = await response.json();
        return data?.ip;
    } catch (e) {
        console.debug('Failed to get outer IP address:', e);
    }
    return "";
}