import { Grid } from 'components/layout/Grid';
import Loader from 'components/loaders/Loader';
import React from 'react';
import { useBankIDLogin } from 'services/auth/bank-id-login/hook';
import { useBankIDOrderStatus } from 'services/auth/bank-id-order-status/hook';

export function BankIDQRCode() {
    const {
        loading,
        onLoginWithBankID,
    } = useBankIDLogin();

    const { qrCodeImage, status } = useBankIDOrderStatus();

    const qrImage = React.useMemo(() => qrCodeImage, [qrCodeImage]);

    React.useEffect(() => {
        onLoginWithBankID();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || status === 'pending') return <Loader />;

    return (
        <Grid
            oneColumn
            noPadding
            style={{ justifyItems: 'center' }}

        >
            {qrImage ? (
                <img
                    id="qrcode-img"
                    src={`data:image/png;base64,${qrImage}`}
                    alt="QR Code"
                    style={{ width: 320, height: 320 }}
                />
            ) : null}
        </Grid>
    );
}
