export const BankIDSigningActions = {
  BANK_ID_SIGNING: '@bankID/signing',
  BANK_ID_SIGNING_INIT_SUCCESS: '@bankID/signing/init/success',
  BANK_ID_SIGNING_SUCCESS: '@bankID/signing/success',
  BANK_ID_SIGNING_UPDATE_STATUS: '@bankID/signing/update/status',
  BANK_ID_SIGNING_FAIL: '@bankID/signing/fail',

  BANK_ID_SIGNING_RESET: '@bankID/signing/reset',
};

export function bankIDSigning(personalNumber, userVisibleData) {
  return {
    type: BankIDSigningActions.BANK_ID_SIGNING,
    payload: { personalNumber, userVisibleData },
  };
}

export function bankIDSigningInitSuccess(orderRef) {
  return {
    type: BankIDSigningActions.BANK_ID_SIGNING_INIT_SUCCESS,
    payload: orderRef,
  };
}

export function bankIDSigningSuccess() {
  return {
    type: BankIDSigningActions.BANK_ID_SIGNING_SUCCESS,
  };
}

export function bankIDSigningFail(error) {
  return {
    type: BankIDSigningActions.BANK_ID_SIGNING_FAIL,
    payload: error,
  };
}

export function bankIDSigningReset() {
  return {
    type: BankIDSigningActions.BANK_ID_SIGNING_RESET,
  };
}
