import { getRequest, postRequest } from 'utils/http';
import {
  AWS_REGION,
  BID_AGGREGATOR_BASE_URL, BID_AGGREGATOR_POLICY, BID_AGGREGATOR_PROVIDER,
  CLIENT_ID, TENANT_ID,
} from 'global/environment';
import { options } from 'global/bankID';
import AWS from 'aws-sdk';
import CryptoJS from 'crypto-js';
import { getUserOuterIpAddress } from 'services/outer-ip-address/api';
import { base64 } from 'utils/format';
import { getUsernameFromPersonalNumber } from './getUsernameFromPersonalNumber';

export async function checkAccountState(personalNumber) {
  const url = `${BID_AGGREGATOR_BASE_URL}/users/resolvable/${getUsernameFromPersonalNumber(personalNumber)}`;
  return getRequest(url, options);
}

export async function initiateBankIDLoginAPI() {
  const authURL = `${BID_AGGREGATOR_BASE_URL}/bid/auth`;

  const endUserIp = await getUserOuterIpAddress();
  const payload = {
    endUserIp,
    requirement: {
      certificatePolicies: [BID_AGGREGATOR_POLICY],
      pinCode: true,
    },
    userVisibleData: base64('Identify to continue'),
    userVisibleDataFormat: 'simpleMarkdownV1',
  };

  return postRequest(authURL, payload, options);
}

export async function loginWithBankIDAPI(initResponse, personalNumber) {
  try {
    const cisp = new AWS.CognitoIdentityServiceProvider({
      region: AWS_REGION,
    });

    const username = getUsernameFromPersonalNumber(personalNumber);

    const authArgs = {
      AuthFlow: 'CUSTOM_AUTH',
      ClientId: CLIENT_ID,
      AuthParameters: {
        USERNAME: username,
      },
    };

    const rv = await cisp.initiateAuth(authArgs).promise();

    if ('ChallengeName' in rv && ['PERSONAL_NUMBER', 'BID_ORDER_REFERENCE'].includes(rv.ChallengeName)) {
      const respondArgs = {
        ChallengeName: 'CUSTOM_CHALLENGE',
        ClientId: CLIENT_ID,
        Session: rv.Session,
        ChallengeResponses: {
          ANSWER: rv.ChallengeName === 'PERSONAL_NUMBER' ? personalNumber : initResponse.orderRef,
          USERNAME: rv.ChallengeParameters.USERNAME,
        },
        ClientMetadata: {
          'bid:rp-name': BID_AGGREGATOR_PROVIDER,
          'bre:tenant-id': TENANT_ID,
        },
      };

      if (rv.ChallengeName === 'PERSONAL_NUMBER') {
        let responseAsBase64 = CryptoJS.enc.Utf8.parse(JSON.stringify(initResponse));
        responseAsBase64 = CryptoJS.enc.Base64.stringify(responseAsBase64);

        respondArgs.ClientMetadata['bid:response'] = responseAsBase64;
      }

      const result = await cisp.respondToAuthChallenge(respondArgs).promise();
      return result;
    }

    return Promise.reject(new Error('genericErrorMessage'));
  } catch (e) {
    return Promise.reject(new Error('genericErrorMessage'));
  }
}
