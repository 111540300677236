import React from 'react';
import { useBankIDSigning } from 'services/auth/bank-id-signing/hook';
import { Card } from 'components/cards/Card';
import { BankID } from 'components/bank-id/BankID';
import { ShouldRender } from 'components/ShouldRender';
import { SigningConfirmation } from '../SigningConfirmation';
import styles from './styles.module.scss';

export const BankIDSigningPopup = ({ children, onClose, showConfiramtionMessage, userVisibleData }) => {
  const {
    onSignWithBankID,
    isSuccessful,
    isInitSuccessful,
    error,
    loading,
    orderRef,
  } = useBankIDSigning();

  const childrenWithOrderRef = React.cloneElement(children, { orderRef, onClose });

  return (
    <Card className={styles.bankIDPopup}>
      {isSuccessful ? childrenWithOrderRef : (
        <>
          <ShouldRender condition={showConfiramtionMessage}>
            <SigningConfirmation />
          </ShouldRender>
          <BankID
            name="bank-id-signing"
            onInit={onSignWithBankID}
            initLoading={loading}
            initSuccess={isInitSuccessful}
            error={error}
            userVisibleData={userVisibleData}
          />
        </>
      )}
    </Card>
  );
};
