import { BID_AGGREGATOR_BASE_URL } from 'global/environment';
import { postRequest } from 'utils/http';
import { options } from 'global/bankID';
import { optionsCollect } from 'global/bankIDCollect';

export async function checkBankIDOrderStatus(sessionId) {
  const bankIDStatusUrl = `${BID_AGGREGATOR_BASE_URL}/bid/collect`;
  return postRequest(bankIDStatusUrl, { 'x-bid-session-id': sessionId }, optionsCollect);
}

export async function cancelBankIDOrderAPI(orderRef) {
  const cancelBankIDLoginURL = `${BID_AGGREGATOR_BASE_URL}/bid/cancel`;
  return postRequest(cancelBankIDLoginURL, { orderRef }, options);
}
