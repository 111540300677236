import { takeLatest, put, call, select } from 'redux-saga/effects';
import { BankIDOrderStatusActions } from 'services/auth/bank-id-order-status/actions';
import {
  BankIDSigningActions,
  bankIDSigningFail,
  bankIDSigningInitSuccess, bankIDSigningSuccess,
} from './actions';
import { bankIDSigningAPI } from './api';

function* bankIDSigningEffect({ payload }) {
  try {
    const { orderRef } = yield call(bankIDSigningAPI, payload.personalNumber, payload.userVisibleData);
    yield put(bankIDSigningInitSuccess(orderRef));
  } catch (e) {
    if (e.statusCode === 404) {
      yield put(bankIDSigningFail('bankIDPersonalNumberUnknown'));
    } else if (e.errorCode === 'alreadyInProgress') {
      yield put(bankIDSigningFail('bankID-RFA4'));
    } else {
      yield put(bankIDSigningFail('bankID-RFA22'));
    }
  }
}

function* bankIDSigningSuccessfulEffect({ payload }) {
  const orderRef = yield select(state => state.bankIDSigning.orderRef);
  if (orderRef === payload.orderRef) {
    yield put(bankIDSigningSuccess());
  }
}

function* bankIDSigningFailEffect({ payload }) {
  const orderRef = yield select(state => state.bankIDSigning.orderRef);
  if (orderRef === payload.orderRef) {
    yield put(bankIDSigningFail(payload.error));
  }
}

export const bankIDSigningEffects = [
  takeLatest(BankIDSigningActions.BANK_ID_SIGNING, bankIDSigningEffect),
  takeLatest(BankIDOrderStatusActions.BANK_ID_ORDER_SUCCESSFUL, bankIDSigningSuccessfulEffect),
  takeLatest(BankIDOrderStatusActions.BANK_ID_ORDER_FAIL, bankIDSigningFailEffect),
];
