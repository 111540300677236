import React from 'react';
import { MultiStep } from 'components/MultiStep';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { BankIDProgressInfo } from './components/BankIDProgressInfo';
import { BankIDQRCode } from './components/BankIDQRCode';

export const BankID = ({
  initLoading,
  error,
}) => (
  <>
    <MultiStep>
      <MultiStep.Step>
        <BankIDQRCode initLoading={initLoading} />
      </MultiStep.Step>

      <MultiStep.Step>
        <BankIDProgressInfo />
      </MultiStep.Step>
    </MultiStep>

    <ShouldRender condition={!!error}>
      <Message negative>
        <FormattedMessage id="genericErrorMessage" />
      </Message>
    </ShouldRender>
  </>
);
