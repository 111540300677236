/*
* Initialize BankID authentication
 */

import { BankIDLoginActions } from './actions';

const initialState = {
  isInitSuccessful: false,
  isSuccessful: false,
  loading: false,
  personalNumber: '',
  orderRef: '',
  error: '',
};

export function bankIDLoginReducer(state = initialState, action) {
  switch (action.type) {
    case BankIDLoginActions.BANK_ID_LOGIN:
      return {
        ...state,
        loading: true,
        error: '',
        personalNumber: action.payload,
      };
    case BankIDLoginActions.BANK_ID_LOGIN_INIT_SUCCESS:
      return {
        ...state,
        isInitSuccessful: true,
        loading: false,
        orderRef: action.payload,
      };
    case BankIDLoginActions.BANK_ID_LOGIN_SUCCESS:
      return {
        ...state,
        isSuccessful: true,
        loading: false,
      };
    case BankIDLoginActions.BANK_ID_LOGIN_GET_CREDENTIALS:
      return {
        ...state,
        isInitSuccessful: false,
      };
    case BankIDLoginActions.BANK_ID_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isInitSuccessful: false,
        error: action.payload,
      };
    case BankIDLoginActions.BANK_ID_LOGIN_RESET:
      return initialState;
    default:
      return state;
  }
}
