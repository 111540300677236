import { useDispatch, useSelector } from 'react-redux';
import { cancelBankIDOrder } from './actions';

export function useBankIDOrderStatus() {
  const { status, orderRef, isCancelling, qrCodeImage } = useSelector(state => state.bankIDOrderStatus);

  const dispatch = useDispatch();

  return {
    status,
    orderRef,
    isCancelling,
    qrCodeImage,
    onCancelOrder: () => dispatch(cancelBankIDOrder(orderRef)),
  };
}
