import React, { useContext, useEffect } from 'react';
import { useBankIDLogin } from 'services/auth/bank-id-login/hook';
import { AppContext } from 'AppContext';
import { useRedirect } from 'utils/redirect';
import { getUserFromToken } from 'utils/auth-token';
import { useLocation } from 'react-router-dom';
import { BankID } from 'components/bank-id/BankID';

export const BankIDLogin = () => {
  const { setIsAuthenticated, setUser } = useContext(AppContext);

  const {
    isInitSuccessful,
    loading,
    error,
    isSuccessful,
    onLoginWithBankID,
  } = useBankIDLogin();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirect = query.get('redirect_url');

  const { onRedirect } = useRedirect(redirect || '/');

  useEffect(() => {
    async function loginUser() {
      const userAttributes = await getUserFromToken();
      setIsAuthenticated(true);
      setUser({ attributes: userAttributes });
      onRedirect();
    }

    if (isSuccessful) {
      loginUser();
    }
  }, [isSuccessful, setIsAuthenticated, setUser, onRedirect]);

  return (
    <BankID
      name="bank-id-login"
      onInit={onLoginWithBankID}
      initLoading={loading}
      initSuccess={isInitSuccessful}
      error={error}
    />
  );
};
