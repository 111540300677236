export const BankIDOrderStatusActions = {
  BANK_ID_UPDATE_STATUS: '@bankID/order/status/update',
  BANK_ID_ORDER_SUCCESSFUL: '@bankID/order/successful',
  BANK_ID_ORDER_FAIL: '@bankID/order/fail',

  BANK_ID_ORDER_CANCEL: '@bankID/order/cancel',
};

export function bankIDUpdateOrderStatus(status, qrCodeImage) {
  return {
    type: BankIDOrderStatusActions.BANK_ID_UPDATE_STATUS,
    payload: { status, qrCodeImage },
  };
}

export function bankIDOrderSuccessful({ orderRef, response }) {
  return {
    type: BankIDOrderStatusActions.BANK_ID_ORDER_SUCCESSFUL,
    payload: { orderRef, response },
  };
}

export function bankIDOrderFail({ orderRef, error }) {
  return {
    type: BankIDOrderStatusActions.BANK_ID_ORDER_FAIL,
    payload: { orderRef, error },
  };
}

export function cancelBankIDOrder(orderRef) {
  return {
    type: BankIDOrderStatusActions.BANK_ID_ORDER_CANCEL,
    payload: orderRef,
  };
}
