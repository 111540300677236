import { useDispatch, useSelector } from 'react-redux';
import { loginEmail, loginEmailReset } from './actions';

export function useLoginWithEmail() {
  const { user, loading, error } = useSelector(state => state.loginWithEmail);

  const dispatch = useDispatch();

  return {
    onEmailLogin: (email, password) => dispatch(loginEmail(email, password)),
    onResetEmailLogin: () => dispatch(loginEmailReset()),
    user,
    loading,
    error,
  };
}
