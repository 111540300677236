import { takeLatest, put, call, select } from 'redux-saga/effects';
import { BankIDOrderStatusActions } from 'services/auth/bank-id-order-status/actions';
import { setAuthToken } from 'utils/auth-token';
import { AppTracking } from 'global/appTracking';
import {
  BankIDLoginActions,
  bankIDLoginFail,
  bankIDLoginGetCredentials,
  bankIDLoginInitSuccess,
  bankIDLoginSuccess,
} from './actions';
import { initiateBankIDLoginAPI, loginWithBankIDAPI } from './api';

function* initBankIDLoginEffect({ payload }) {
  try {
    const initLogin = yield call(initiateBankIDLoginAPI, payload);
    yield put(bankIDLoginInitSuccess(initLogin['x-bid-session-id']));
  } catch (e) {
    yield put(bankIDLoginFail(e.message));
  }
}

function* bankIDLoginSuccessfulEffect({ payload }) {
  try {
    const { orderRef } = yield select(state => state.bankIDLogin);
    if (orderRef === payload.orderRef) {
      yield put(bankIDLoginGetCredentials());

      const { AuthenticationResult } = yield call(loginWithBankIDAPI, payload.response, payload.response['x-bid-personal-number']);
      setAuthToken(AuthenticationResult.IdToken, AuthenticationResult.AccessToken);

      yield put(bankIDLoginSuccess());
      AppTracking.authEvent({ action: 'Login', label: 'BankID' });
    }
  } catch (e) {
    yield put(bankIDLoginFail('genericErrorMessage'));
  }
}

function* bankIDLoginFailEffect({ payload }) {
  const orderRef = yield select(state => state.bankIDLogin.orderRef);
  if (orderRef === payload.orderRef) {
    yield put(bankIDLoginFail(payload.error));
  }
}

export const bankIDLoginEffects = [
  takeLatest(BankIDLoginActions.BANK_ID_LOGIN, initBankIDLoginEffect),
  takeLatest(BankIDOrderStatusActions.BANK_ID_ORDER_SUCCESSFUL, bankIDLoginSuccessfulEffect),
  takeLatest(BankIDOrderStatusActions.BANK_ID_ORDER_FAIL, bankIDLoginFailEffect),
];
